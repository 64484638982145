.Bewerber{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    z-index: 1;
  
}

.WirSuchenDich {
    height: 50vh !important;
    z-index: 1;
}
.colCenter > p > svg{
  width: 30px !important;
  height: 30px !important;
}

.ZitatBild{
  display: flex !important;
  justify-content: center;
}

.WerSindWirBild{
  display: flex !important;
  width: 29vw;
  height: 29vw;
  padding-top: 5vh;
}

.WirSuchenDichText{
  z-index: 1;
    padding-top: 20vh;
    color: #fff !important;
    height: auto !important;
    font-size: 3.5vw;
    width: fit-content;
    margin: auto;
    text-shadow: 0px 0px 10px rgb(0 0 0 / 61%);
  
    -webkit-text-stroke: 1.11px black; /* stroke width and color */
    -webkit-font-smoothing: antialiased;
    font-weight: bold;
}
.WerSindWir{
  z-index: 1;
  height: 100% !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
}
.WerSindWirText{
  z-index: 1;
    color: #fff !important;
    height: auto !important;
    font-size: 2.5rem;
    width: fit-content;
    margin: auto;
    text-shadow: 0px 0px 10px rgb(0 0 0 / 61%);
  font-family: "Open Sans", sans-serif;
}


.Button2{
  z-index: 1;
  font-family: "Open Sans", sans-serif;
    top: 3vh;
    font-size: 2.5rem;
    height: auto;
    width: auto;
    text-align: center !important;
    margin: auto;
    display: flex;
    color: white !important;
    background-color: transparent !important;
    -webkit-text-stroke: 1.11px black; /* stroke width and color */
    -webkit-font-smoothing: antialiased;
    font-weight: bold;
  }

.ButtonJob{
    z-index: 1;
    padding-bottom: 5vh;
  font-family: "Open Sans", sans-serif;
    font-size: 1.25rem;
    height: auto;
    width: auto;
    display: flex;
    color: rgb(0, 0, 0) !important;
    background-color: transparent !important;
}

  .WerSindWirSchrift{
    z-index: 1;
        font-family: "Open Sans", sans-serif;
  }

  .WasMachtUns{
    z-index: 1;
    height: auto !important;
  }
  .WasMachtUnsText{
    z-index: 1;
    color: black !important;
    height: auto !important;
    font-size: 2.5rem;
    width: fit-content;
    margin: auto;
  
    -webkit-text-stroke: 1.11px black; /* stroke width and color */
    -webkit-font-smoothing: antialiased;
}

.GallerieBewerber {
  z-index: 1;
    height: 30vh;
    display: flex !important;
    align-items: center;
    justify-content: center;
  }

  .GallerieBewerber::before  {
    z-index: 0;
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(
      to top,
      rgba(113, 113, 113, 0.7) 0%,
      rgba(85, 85, 85, 0.3) 100%
    );
  }

  .ZitatGrid{
    z-index: 1;
    text-align: center;
      font-family: "Open Sans", sans-serif;
    font-size: 1rem;
    display: flex;
    justify-content: center;
    color: #fff;
  }

  .DasMachtUnsGrid{
    z-index: 1;
    padding-top: 3vh;
    padding-bottom: 3vh;
      font-family: "Open Sans", sans-serif;
    font-size: 1rem;
    display: flex;
    justify-content: center;
  }

  .DeinNeuerJob{
    z-index: 1;
    padding-top: 5vh;
    padding-bottom: 3vh;
      font-family: "Open Sans", sans-serif;
    font-size: 1rem;
    display: flex;
    justify-content: center;
  }

  .DasMachtUnsGridH1{
    z-index: 1;
      font-family: "Open Sans", sans-serif;
    font-size: 1rem;
    display: flex;
    justify-content: center;
  }

  .DasMachtUnsGrid2{
    z-index: 1;
    padding-top: 2vh;
    text-align: left;
      font-family: "Open Sans", sans-serif;
    font-size: .95rem;
    display: flex;
  }

  .mantine-1mfzpki{
      font-family: "Open Sans", sans-serif;
  }
  .mantine-9y67rq{
      font-family: "Open Sans", sans-serif;
  }

  .WerSindWirsLinks{
    z-index: 1;
  }
  .WerSindWirRechts{
    z-index: 1;
    line-height: 2;
  }

 
.Kerem{
  z-index: 1;
  display: flex !important;
  justify-content: center;
  width: 160px;
  height: 160px;

}

.ZitatBildGroesse{
  z-index: 1;
  width: 160px;
  height: 160px;
}

.AktuellGrid{
  z-index: 1;
  padding-top: 3vh;
    font-family: "Open Sans", sans-serif;
  display: flex;
  justify-content: center;
}

.colCenter{
text-align: center !important;
  font-family: "Open Sans", sans-serif;
font-size: 1rem;

}

.mantine-qo1k2{
  overflow: visible !important;
}
/* --------------------------------------------------------------------------------*/
@media only screen and (max-width: 1200px) {

  .DasMachtUnsGrid{
    font-size: 0.75rem !important;
  }
}
/* --------------------------------------------------------------------------------*/

@media only screen and (max-width: 900px) {
  .DasMachtUnsGrid2{
    padding-top: 2vh;
    text-align: left;
      font-family: "Open Sans", sans-serif;
    display: flex;
  }

  .DasMachtUnsGrid{
    z-index: 1;
    padding-top: 3vh;
    padding-bottom: 3vh;
      font-family: "Open Sans", sans-serif;
    font-size: 0.6rem !important;
    display: flex;
    justify-content: center;
  }
  .WerSindWirRechts{
    z-index: 1;
    line-height: 2;
    display: contents;

  }

  .ZitatBildGroesse{
    z-index: 1;
    width: 80px;
    height: 80px;
  }

  .Kerem{
    display: flex !important;
    width: 120px;
    height: 120px;
  }

  .ZitatBild{
    display: flex !important;
    justify-content: center;
  }

  .AktuelleStellen{
    height: auto !important;
  }

  .ButtonJob{
      font-size: 0.9rem;

  }

  .colCenter{
    text-align: center !important;
      font-family: "Open Sans", sans-serif;
    font-size: 0.65rem;
  }

  .Icons > span > svg{
    width: 30px !important;
    height: 30px !important;
  }

  .WirSuchenDichText{
    z-index: 1;
      font-size: 4.5vw;
  }

  .WerSindWirBild{
    display: flex !important;
    width: 35vw;
    height: 35vw;
  }

}

/* --------------------------------------------------------------------------------*/
