.Karriere {
  top: 5vh;
  width: 5%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 1;
  background: white;
}
.Button1{
  font-family: "Open Sans", sans-serif;
  top: 3vh;
  font-size: 2.5vw;
  height: auto;
  text-align: center !important;
  margin: auto;
  display: flex;
  color: rgba(0, 41, 43) !important;
  background-color: transparent !important;
}
.Karriere .menuTitle {
  font-size: 60vw;
}

.mantine-11qulmq{
  color: rgba(0, 54, 66) !important;
  background-color: white !important;
  text-decoration: underline !important;
}

.mantine-1uyej08{
  color: rgba(0, 54, 66) !important;
  text-align: center !important;
  text-decoration: underline !important;

}



.mantine-qo1k2:hover{
 background-color: transparent;
 text-decoration:underline;
}

.menuList {
  height: auto;
  display: grid;
  gap: 10vh;
  grid-template-columns: 1fr 1fr;
}

/* MENU ITEM STYLING */

.menuItem {
  width: 500px;
  text-align: center;
  height: auto;
  margin: 20px;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
}
.menuItem:hover {
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.5);
  transition: 0.3s ease-in;
  cursor: pointer;
}

.menuItem div {
  width: 100%;
  height: 200px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.mantine-11qulmq {
  color: #228be6;
  font-weight: 600;
  background-color: rgba(231, 245, 255, 1);
  width: 100%;
  height: 36px;
  margin-top: 2vh;
}
.ÜberUnsHome {
  padding-top: 6.5vh;
  font-size: 3.5rem;

  font-weight: 600;
  text-align: center;
}

.menuItem h1,
.menuItem p {
  margin-left: 20px;
  font-family: "Open Sans", sans-serif;
  z-index: 1;
}


@media only screen and (max-width: 1200px) {
  .menuList {
    grid-template-columns: 1fr 1fr;
  }
  .Button1{
    font-family: "Open Sans", sans-serif;
    top: 3vh;
    font-size: 3.5vw;
    height: auto;
    text-align: center !important;
    margin: auto;
    display: flex;
    color: rgba(0, 41, 43) !important;
    background-color: transparent !important;
  }

  .menuList {
    grid-template-columns: 1fr;
  }
  .Karriere {
    margin: 10vh 0;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .Karriere .menuTitle {
    font-size: 60px;
  }

  .CardsChange{
    width: 75% !important;
  }
}

@media only screen and (max-width: 800px) {
  .Button1{
    font-family: "Open Sans", sans-serif;
    top: 3vh;
    font-size: 4.5vw;
    height: auto;
    text-align: center !important;
    margin: auto;
    display: flex;
    color: rgba(0, 41, 43) !important;
    background-color: transparent !important;
  }
}
