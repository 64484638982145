.Navbar {
  position: sticky;
  width: 100%;
  height: 5em;
  background-color: rgb(255 255 255);
  display: flex;
  align-items: center;
  z-index: 99;
  top: 0;

  opacity: 0.95;
}

.Navbar a {
  color: black;
  text-decoration: none;
  margin: 0px;
  font-weight: 300;
}

.Navbar a:hover{
  color: rgba(234,94,38,255);
  margin: 0px;
}

.Navbar-header {
  padding-left: 10px;
  display: inline-block;
}

.NavbarButtons {
  border: none ;
  background: none ;
  color: black ;
  font-size: 1.2em ;
  padding-top: 0 ;
  line-height: 1.5715 ;
  text-decoration: none;
  padding-left: 3vh;
  padding-right: 0vh;
}

.NavbarButtons ::after {
  content: "";
  display: block;
  width: 0;
  height: 3px;
  background: #000;
  transition: width 0.3s;
}
.NavbarButtons:hover ::after {
  width: 100% !important;
}
.NavbarButtons a {
  margin: 0px !important;
}

.NavbarLinks {
  display: inline-block;
}

.DropdownMenue {
  background-color: white !important;
  color: black !important;
}

@media only screen and (max-width: 900px) {
  .navbar .rightSide a {
    width: 70px;
  }
  .navbar .leftSide {
    padding-left: 50px;
  }
}

@media only screen and (max-width: 600px) {
  .navbar .rightSide a {
    display: none;
  }

  .navbar .rightSide {
    justify-content: flex-end;
    padding-right: 50px;
  }

  .navbar .rightSide button {
    display: inherit;
  }

  .NavbarButtons {
    font-size: 3.5vw ;
  }
  
}

@media only screen and (min-width: 600px) {
  .navbar .rightSide button {
    display: none;
  }

  .hiddenLinks {
    display: none;
  }
}
