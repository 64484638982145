@font-face {
  font-family: 'OpenSans';
  src: url('../fonts/OpenSans-VariableFont_wdth,wght.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'OpenSans';
  src: url('../fonts/OpenSans-Italic-VariableFont_wdth,wght.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}
body {
  font-family: 'OpenSans', sans-serif;
}
.App {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  font-family: 'OpenSans', sans-serif;
}

body {
  padding: 0;
  margin: 0;
  font-family: 'OpenSans', sans-serif;
  font-weight: 400;
}

ul {
  font-family: 'OpenSans', sans-serif;
}
