.about {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  z-index: 1;
  background: white;

  background-size: 100% !important;
}

.aboutTop {
  width: 100%;
  height: 50vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

}

.aboutBottom {
  display: flex;
  flex-direction: column;
  height: auto;
  width: 60%;
  margin: 0 auto;
  align-items: center;
  margin-top: 50px;
}

.aboutBottom p {
  width: 80%;
  font-family: "Open Sans", sans-serif;
}

.about .aboutBottom h1 {
  font-weight: 400;
  font-size: 70px;
  color: black;
  height: auto;
  justify-content: center;

}

.FadeDownCards {
  display: flex;
  justify-content: center;
  height: auto;
}

.CardTitel {
  font-weight: 600 !important;
  font-size: 1.5rem;
  text-align: center;
  width: 100%;
}
.CardTitel2 {
  font-size: 1.15rem;
  text-align: center;
  width: 100%;
}

.CardText {
  font-size: 1rem;
  font-weight: 400;

}
.ÜberUnsHome {
  padding-top: 6.5vh;
  font-size: 3.5rem;

  font-weight: 600;
  text-align: center;
  z-index: 1;
  color: rgb(0, 41, 43);
}

@media only screen and (max-width: 1200px) {
  .about {
    margin: 5vh 0;
    width: 100%;
    height: auto;
    display: flex;
  }
    .FadeDownCards {
      display:block;
      width:auto;
      height: auto;
      padding-left: 20vw;
    }

    .CardsChange{
      width: 75% !important;
    }
}
