/* Add your CSS styles here */

.card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.card {
    width: 33%;
    padding: 20px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

h1 {
    font-size: 24px;
    margin-bottom: 20px;
}

h2 {
    font-size: 20px;
    margin-bottom: 10px;
    font-weight: 600;
}

h3 {
    font-size: 16px;
    margin-bottom: 10px;
    font-weight: 600;
}

ul {
    margin-bottom: 10px;
    padding-left: 20px;
}

li {
    margin-bottom: 5px;
}

p {
    margin-bottom: 10px;
}

a {
    color: #007bff;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}
h1{
    margin: auto auto;
    width: fit-content;
    font-size: 2rem;
    font-weight: bold;
}
/* Responsive Styles */
@media (max-width: 768px) {

    .card-container {
        flex-wrap: nowrap;
        justify-content: flex-start;
        flex-direction: column;
        align-items: center;

    }
    
    .card {
        width: 90%;
    }
}
