#Impressum {
  height: auto;
  background-color: white;
  z-index: 1;
  text-align: center;
  font-size: 0.85vw;
  padding: 8vh 0;
  gap: 10vh;
  justify-content: center;

  text-align: justify
}

#ImpressumBeschreibung1 {
  width: 90%;
  text-align: center;
  margin: 0 auto;
  text-align: justify
}

#ImpressumBeschreibung1 h3 {
  text-align: center;
  font-size: 1.5vw;
  margin: 0 auto;
}

