.JobBild {
    height: 50vh !important;
}

.JobVorlageText {
    padding-top: 14vh;
    color: #fff !important;
    height: auto !important;
    font-size: 3.5rem;
    width: fit-content;
    margin: auto;
    text-shadow: 0px 0px 10px rgb(0 0 0 / 61%);

    -webkit-text-stroke: 1.11px black;
    /* stroke width and color */
    -webkit-font-smoothing: antialiased;
    font-weight: bold;
}

.Divider::after {
    width: 1px !important;
    background-color: gray !important;
    height: 80%;
    margin: 0 auto !important;
    position: absolute;
    content: "";
}

.Icons>span>svg {
    width: 30px !important;
    height: 30px !important;
}




@media only screen and (max-width: 900px) {
    .Divider::after {
        width: 0px !important;
        background-color: gray !important;
        height: 90%;
        margin: 0 auto !important;
        position: absolute;
        content: "";
    }

    .JobLinks {
        margin-right: 20vh;
        margin-left: 20vh;
    }

}

.Divider {
    height: 100%;
    padding-top: 5vh;
    text-align: center;
    font-family: "Open Sans", sans-serif;
    font-size: 110% !important;
    display: flex;
    justify-content: center;
}

.JobLinks {
    margin-right: 20vh;
}

.BarisB2 {
    display: flex !important;
    justify-content: center;
}