#Leistungen {
  height: auto;
  background-color: white;
  z-index: 1;
  text-align: center;
  font-size: 0.85vw;
  padding: 8vh 0;
  gap: 5vh;
  justify-content: center;
  font-family: "Open Sans", sans-serif;
  text-align: justify;
  display: flex;
    flex-direction: column;
}

#LeistungenBeschreibung1 { 
  width: 66%;
  text-align: center;
  margin: 0 auto;
  text-align: justify;
  font-family: "Open Sans", sans-serif;

}

#LeistungenBeschreibung1 h3 {
  text-align: center;
  font-size: 1.5vw;
  margin: 0 auto;
  margin-bottom: 2vh;
  font-family: "Open Sans", sans-serif;

}
#LeistungenBeschreibung2{
  width: 66%;
  margin: 0 auto;
  font-family: "Open Sans", sans-serif;

}
